<template>
  <div class="d-flex flex-column justify-content-between">
    <v-row dense>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-card class="pa-5">
              <v-row dense>
                <v-col
                  cols="12"
                  class="d-flex flex-row justify-md-start justify-center"
                >
                  <span class="d-flex flex-row justify-start align-center">
                    <h2>{{ $t("api_credentials") }}</h2>
                    <v-tooltip top :max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          medium
                          v-on="on"
                          class="ml-6 color-gray-text"
                        >
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("api_credentials_tooltip") }}
                        <br />
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12">
            <v-card class="pa-5">
              <v-row dense>
                <v-col
                  cols="12"
                  class="d-flex flex-row justify-md-start justify-center"
                >
                  <span class="d-flex flex-row justify-start align-center">
                    <h2>{{ $t("api_credentials_active_api") }}</h2>
                    <v-tooltip top :max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          medium
                          v-on="on"
                          class="ml-6 color-gray-text"
                        >
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("api_credentials_active_api_tooltip") }}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-simple-table class="fontUrbanist">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left text-xl">
                            {{ $t("api_permissions_table_header") }}
                          </th>
                          <th class="text-center text-xl">
                            {{ $t("api_active_table_header") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-left text-lg">
                            {{
                              $t(
                                "api_credentials_is_allowed_corporate_api_partner"
                              )
                            }}
                            <span class="ml-2">
                              <v-tooltip top :max-width="400">
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    medium
                                    v-on="on"
                                    class="ml-6 color-gray-text"
                                  >
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span>
                                  {{ $t("api_credentials_is_allowed_corporate_api_partner_tooltip") }}
                                </span>
                              </v-tooltip>
                            </span>
                          </td>
                          <td class="text-center text-lg">
                            <v-chip
                              :color="
                                is_allowed_corporate_api_partner
                                  ? 'success'
                                  : 'error'
                              "
                              >{{
                                is_allowed_corporate_api_partner
                                  ? $t("yes")
                                  : "No"
                              }}</v-chip
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left text-lg">
                            {{ $t("api_credentials_is_corporate_api_partner") }}
                            <span class="ml-2">
                              <v-tooltip top :max-width="400">
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    medium
                                    v-on="on"
                                    class="ml-6 color-gray-text"
                                  >
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span>
                                  {{ $t("api_credentials_is_corporate_api_partner_tooltip") }}
                                </span>
                              </v-tooltip>
                            </span>
                          </td>
                          <td class="text-center text-lg">
                            <v-chip
                              :color="
                                is_corporate_api_partner ? 'success' : 'error'
                              "
                              >{{
                                is_corporate_api_partner ? $t("yes") : "No"
                              }}</v-chip
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left text-lg">
                            {{
                              $t(
                                "api_credentials_is_allowed_corporate_shipment_api"
                              )
                            }}
                            <span class="ml-2">
                              <v-tooltip top :max-width="400">
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    medium
                                    v-on="on"
                                    class="ml-6 color-gray-text"
                                  >
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span>
                                  {{ $t("api_credentials_is_allowed_corporate_shipment_api_tooltip") }}
                                </span>
                              </v-tooltip>
                            </span>
                          </td>
                          <td class="text-center text-lg">
                            <v-chip
                              :color="
                                is_allowed_corporate_shipment_api
                                  ? 'success'
                                  : 'error'
                              "
                              >{{
                                is_allowed_corporate_shipment_api
                                  ? $t("yes")
                                  : "No"
                              }}</v-chip
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" lg="12">
            <v-card class="pa-5">
              <v-row dense>
                <v-col
                  cols="12"
                  class="d-flex flex-row justify-md-start justify-center"
                >
                  <span class="d-flex flex-row justify-start align-center">
                    <h2>{{ $t("api_credentials_token") }}</h2>
                    <v-tooltip top :max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          medium
                          v-on="on"
                          class="ml-6 color-gray-text"
                        >
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("api_credentials_token_tooltip") }}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    :value="apiCredentials.token"
                    readonly
                    outlined
                    dense
                    :label="$t('api_credentials_token_label')"
                    hint="Token"
                    :type="isTokenVisible ? 'text' : 'password'"
                    :append-icon="
                      isTokenVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    @click:append="isTokenVisible = !isTokenVisible"
                    :loading="loadingToken"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-tooltip bottom :max-width="400">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        medium
                        v-on="on"
                        v-bind="attrs"
                        @click="handleCopyClipboard()"
                        :disabled="loadingToken"
                      >
                        <v-icon medium class="color-gray-text">
                          {{ icons.mdiContentCopy }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("copy_to_clipboard") }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" class="d-flex justify-lg-start justify-center">
                  <v-btn
                    color="primary"
                    :loading="loadingToken"
                    @click="generateToken()"
                  >
                    {{ $t("api_credentials_generate_token") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12">
            <v-card class="pa-5">
              <v-row dense>
                <v-col
                  cols="12"
                  class="d-flex flex-row justify-md-start justify-center"
                >
                  <span class="d-flex flex-row justify-start align-center">
                    <h2>{{ $t("api_credentials_webhook_title") }}</h2>
                    <v-tooltip top :max-width="400">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          medium
                          v-on="on"
                          class="ml-6 color-gray-text"
                        >
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("api_credentials_webhook_title_tooltip") }}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
              </v-row>
              <ValidationObserver v-slot="{ invalid }" ref="webHookForm">
                <v-form
                  @submit.prevent="handleWebhook()"
                  class="mt-4"
                  :disabled="loadingWebhook"
                >
                  <v-row dense>
                    <v-col cols="12" lg="10">
                      <ValidationProvider
                        name="Webhook URL"
                        :rules="{
                          required: true,
                          regex: /^(http:\/\/|https:\/\/).*/,
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          label="Webhook URL"
                          dense
                          v-model="apiCredentials.webhookURL"
                          hint="Colocar la dirección del webhook"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <ValidationProvider
                        name="Webhook Method"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          label="Webhook Method"
                          outlined
                          dense
                          :items="['POST']"
                          v-model="apiCredentials.webhookRequestType"
                          :error-messages="errors[0]"
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      cols="12"
                      class="d-flex justify-lg-start justify-center"
                    >
                      <v-btn
                        color="primary"
                        :loading="loadingWebhook"
                        type="submit"
                        :disabled="invalid"
                      >
                        {{ $t("api_credentials_send_webhook") }}
                      </v-btn>
                      <v-btn
                        v-if="isGotWebHook"
                        class="ml-2"
                        color="error"
                        :loading="loadingWebhook"
                        @click="handleWebhook(true)"
                      >
                        {{ $t("api_credentials_send_webhook_deactivate") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
  mdiContentCopy,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";

import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import { ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiContentCopy,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      apiCredentials: {
        token:
          "***************************************************************************************************************************************",
        webhookURL: null,
        webhookRequestType: null,
        isGotWebHook: false,
      },
      loadingToken: false,
      isTokenVisible: false,
      loadingWebhook: false,
    };
  },
  methods: {
    ...mapActions("auth", ["me"]),
    ...mapActions(["setLoading"]),

    handleCopyClipboard() {
      try {
        navigator.clipboard.writeText(this.apiCredentials.token);
        this.$dialog.notify.success(this.$t("copy_to_clipboard_success"));
      } catch (error) {
        console.error(error);
      }
    },
    async getAndHandleApiCrdentialsDetails() {
      try {
        const { data } = await axios.get(
          "/api_partner/get_corporate_api_details"
        );
        if (!data || !data.success) {
          throw new Error("Error obteniendo datos");
        }
        const { token, webhook_url, webhook_request_type } = data.api_partner;
        if (!token) {
          throw new Error("Error obteniendo datos");
        }
        this.apiCredentials.token = token;
        this.apiCredentials.webhookURL = webhook_url;
        this.apiCredentials.webhookRequestType = webhook_request_type;
        this.isGotWebHook =
          Boolean(webhook_url) && Boolean(webhook_request_type);
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error("Error obteniendo datos");
      }
    },
    async generateToken() {
      try {
        this.loadingToken = true;
        const { data } = await axios.post(
          "/corporate/generate_new_corporate_api_partner_token"
        );
        if (!data || !data.success) {
          throw new Error("Error obteniendo datos");
        }
        const { token } = data.api_partner;
        if (!token) {
          throw new Error("Error obteniendo datos");
        }
        this.apiCredentials.token = token;
        this.$dialog.notify.success("Token nuevo generado");
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error("Error generando token");
      } finally {
        this.loadingToken = false;
      }
    },
    async handleWebhook(deactivate = false) {
      this.loadingWebhook = true;
      try {
        if (!deactivate) {
          const isValidForm = this.$refs.webHookForm.validate();
          if (!isValidForm) {
            return;
          }
        }

        let payload;

        if (deactivate) {
          payload = {
            webhook_url: null,
            webhook_request_type: null,
          };
        } else {
          payload = {
            webhook_url: this.apiCredentials.webhookURL,
            webhook_request_type: this.apiCredentials.webhookRequestType,
          };
        }

        const webhookResponse = await axios.post(
          "/api_partner/submit_webhook_details",
          payload
        );

        if (
          !webhookResponse ||
          !webhookResponse?.data ||
          !webhookResponse?.data?.success
        ) {
          throw new Error("Error in webhookResponse");
        }

        const { webhook_url, webhook_request_type } = webhookResponse.data;

        if (!deactivate) {
          this.apiCredentials.webhookURL = webhook_url;
          this.apiCredentials.webhookRequestType = webhook_request_type;
          this.isGotWebHook =
            Boolean(webhook_url) && Boolean(webhook_request_type);
        }

        this.$dialog.notify.success("Webhook actualizado");
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error("Error enviando webhook");
      } finally {
        if (deactivate) {
          location.reload();
        }
        this.loadingWebhook = false;
      }
    },
  },
  computed: {
    ...mapState("auth", [
      "is_allowed_corporate_api_partner",
      "is_corporate_api_partner",
      "is_allowed_corporate_shipment_api",
    ]),
  },
  async created() {
    this.setLoading(true);
    this.loadingToken = true;
    await this.me();
    await this.getAndHandleApiCrdentialsDetails();
    this.loadingToken = false;
    this.setLoading(false);
  },
};
</script>
<style lang="scss" scoped></style>
