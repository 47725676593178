var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("api_credentials")))]),_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_tooltip"))+" "),_c('br')])])],1)])],1)],1)],1)],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("api_credentials_active_api")))]),_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_active_api_tooltip"))+" ")])])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"fontUrbanist",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left text-xl"},[_vm._v(" "+_vm._s(_vm.$t("api_permissions_table_header"))+" ")]),_c('th',{staticClass:"text-center text-xl"},[_vm._v(" "+_vm._s(_vm.$t("api_active_table_header"))+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-left text-lg"},[_vm._v(" "+_vm._s(_vm.$t( "api_credentials_is_allowed_corporate_api_partner" ))+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_is_allowed_corporate_api_partner_tooltip"))+" ")])])],1)]),_c('td',{staticClass:"text-center text-lg"},[_c('v-chip',{attrs:{"color":_vm.is_allowed_corporate_api_partner
                                ? 'success'
                                : 'error'}},[_vm._v(_vm._s(_vm.is_allowed_corporate_api_partner ? _vm.$t("yes") : "No"))])],1)]),_c('tr',[_c('td',{staticClass:"text-left text-lg"},[_vm._v(" "+_vm._s(_vm.$t("api_credentials_is_corporate_api_partner"))+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_is_corporate_api_partner_tooltip"))+" ")])])],1)]),_c('td',{staticClass:"text-center text-lg"},[_c('v-chip',{attrs:{"color":_vm.is_corporate_api_partner ? 'success' : 'error'}},[_vm._v(_vm._s(_vm.is_corporate_api_partner ? _vm.$t("yes") : "No"))])],1)]),_c('tr',[_c('td',{staticClass:"text-left text-lg"},[_vm._v(" "+_vm._s(_vm.$t( "api_credentials_is_allowed_corporate_shipment_api" ))+" "),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_is_allowed_corporate_shipment_api_tooltip"))+" ")])])],1)]),_c('td',{staticClass:"text-center text-lg"},[_c('v-chip',{attrs:{"color":_vm.is_allowed_corporate_shipment_api
                                ? 'success'
                                : 'error'}},[_vm._v(_vm._s(_vm.is_allowed_corporate_shipment_api ? _vm.$t("yes") : "No"))])],1)])])]},proxy:true}])})],1)],1)],1)],1)],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("api_credentials_token")))]),_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_token_tooltip"))+" ")])])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"value":_vm.apiCredentials.token,"readonly":"","outlined":"","dense":"","label":_vm.$t('api_credentials_token_label'),"hint":"Token","type":_vm.isTokenVisible ? 'text' : 'password',"append-icon":_vm.isTokenVisible
                      ? _vm.icons.mdiEyeOffOutline
                      : _vm.icons.mdiEyeOutline,"loading":_vm.loadingToken},on:{"click:append":function($event){_vm.isTokenVisible = !_vm.isTokenVisible}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","medium":"","disabled":_vm.loadingToken},on:{"click":function($event){return _vm.handleCopyClipboard()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"color-gray-text",attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentCopy)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("copy_to_clipboard"))+" ")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-lg-start justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingToken},on:{"click":function($event){return _vm.generateToken()}}},[_vm._v(" "+_vm._s(_vm.$t("api_credentials_generate_token"))+" ")])],1)],1)],1)],1)],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("api_credentials_webhook_title")))]),_c('v-tooltip',{attrs:{"top":"","max-width":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("api_credentials_webhook_title_tooltip"))+" ")])])],1)])],1),_c('ValidationObserver',{ref:"webHookForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mt-4",attrs:{"disabled":_vm.loadingWebhook},on:{"submit":function($event){$event.preventDefault();return _vm.handleWebhook()}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('ValidationProvider',{attrs:{"name":"Webhook URL","rules":{
                        required: true,
                        regex: /^(http:\/\/|https:\/\/).*/,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Webhook URL","dense":"","hint":"Colocar la dirección del webhook","error-messages":errors[0]},model:{value:(_vm.apiCredentials.webhookURL),callback:function ($$v) {_vm.$set(_vm.apiCredentials, "webhookURL", $$v)},expression:"apiCredentials.webhookURL"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('ValidationProvider',{attrs:{"name":"Webhook Method","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Webhook Method","outlined":"","dense":"","items":['POST'],"error-messages":errors[0]},model:{value:(_vm.apiCredentials.webhookRequestType),callback:function ($$v) {_vm.$set(_vm.apiCredentials, "webhookRequestType", $$v)},expression:"apiCredentials.webhookRequestType"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-lg-start justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingWebhook,"type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("api_credentials_send_webhook"))+" ")]),(_vm.isGotWebHook)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","loading":_vm.loadingWebhook},on:{"click":function($event){return _vm.handleWebhook(true)}}},[_vm._v(" "+_vm._s(_vm.$t("api_credentials_send_webhook_deactivate"))+" ")]):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }